import { NavigationPage, TitledCheckedList } from '@/app/components'
import UsernamePasswordInputs, {
  useUsernamePasswordInputs,
} from '@/app/features/connect-device/UsernamePasswordInputs'
import { Button, CardAction, CardActions } from '@/components'
import {
  useAppDispatch,
  useMockableViewModel,
  useUrlSearchParam,
} from '@/hooks'
import ChargePointAuthenticateStatusPage from './status'
import { vehicleChargersCollection } from '@/reducers/vehicleChargers'
import { useHistory, useLocation } from 'react-router-dom'
import { ComponentProps } from 'react'
import { VehicleCharger } from '@/types'
import { surveyResponsesCollection } from '@/reducers/surveyResponses'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'
import { chargerEnrollmentCollection } from '@/reducers/deviceProgramEnrollments'

type ConnectionStatus = ComponentProps<
  typeof ChargePointAuthenticateStatusPage
>['status']

const useChargePointStatusHandler = (
  createVehicleChargerAction: typeof vehicleChargersCollection.actions.create,
) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const statusParam = useUrlSearchParam('status')

  const updateStatus = (newStatus: ConnectionStatus) => {
    history.push({ search: `?status=${newStatus}` })
  }
  const clearStatus = () => {
    history.push({ search: '' })
  }

  const onSubmit = async (values: { username: string; password: string }) => {
    updateStatus('connecting')
    const response = await dispatch(createVehicleChargerAction(values))
    const responseType = response?.type as string | undefined

    if (
      !responseType ||
      responseType !== vehicleChargersCollection.actionTypes.create.Success
    ) {
      updateStatus('failed')
      return
    }

    updateStatus('connected')
  }

  const status = ['connecting', 'connected', 'failed'].includes(
    statusParam ?? '',
  )
    ? statusParam
    : null

  return {
    onSubmit,
    clearStatus,
    status: status as null | ConnectionStatus,
  }
}

const useViewModel = useMockableViewModel({
  useViewModel() {
    const history = useHistory()
    const isAva = useIsAvaBasePath()
    const isOnboarding = useLocation().pathname.includes('/onboarding/')
    const dispatch = useAppDispatch()
    const {
      createAction: createVehicleChargerAction,
      data: createdVehicleChargers,
      error: createVehicleChargerError,
    } = vehicleChargersCollection.useCreate({
      path: '/charge_point_authentication',
    })
    const { onSubmit, status, clearStatus } = useChargePointStatusHandler(
      createVehicleChargerAction,
    )
    const onStatusConfirmed = () => {
      const chargerId = createdVehicleChargers[0]?.id
      dispatch(surveyResponsesCollection.actions.fetch())
      dispatch(chargerEnrollmentCollection.actions.invalidate())
      if (chargerId) {
        if (isAva && isOnboarding) {
          history.push('/ava/onboarding/add-another')
          return
        }
        history.push(`/devices/vehicle-charger/${chargerId}`)
        return
      } else {
        history.push('/app')
        return
      }
    }

    const creationErrorMessage =
      createVehicleChargerError?.status === 401
        ? 'Invalid username or password'
        : undefined
    return {
      ...useUsernamePasswordInputs({ onSubmit }),
      status: status as null | 'connecting' | 'connected' | 'failed',
      onStatusConfirmed,
      onRestart: clearStatus,
      createdVehicleChargers,
      creationError: creationErrorMessage,
    }
  },
  useMockViewModel() {
    return {
      ...useUsernamePasswordInputs({
        onSubmit: (values: { username: string; password: string }) =>
          console.log(values),
      }),
      status: null as null | 'connecting' | 'connected' | 'failed',
      onStatusConfirmed: () => {},
      onRestart: () => {},
      createdVehicleChargers: [{ id: 1 } as VehicleCharger],
      creationError: undefined as string | undefined,
    }
  },
})

export default function ChargePointAuthenticatePage() {
  const {
    inputValues,
    inputErrors,
    onSubmit,
    onValueChange,
    status,
    onStatusConfirmed,
    onRestart,
    createdVehicleChargers,
    creationError,
  } = useViewModel()

  return (
    <NavigationPage
      id="chargepoint-authentcation-connect-page"
      title="Sign in to Chargepoint"
      subtitle="Enter your Chargepoint login credentials to track your electricity
            consumption."
    >
      <form
        noValidate
        autoComplete="off"
        style={{ height: '100%' }}
        onSubmit={onSubmit}
      >
        <NavigationPage.SingleCardContent>
          <UsernamePasswordInputs
            className="mb-[52px]"
            inputValues={inputValues}
            inputErrors={inputErrors}
            onValueChange={onValueChange}
          />

          <TitledCheckedList
            title="Permissions"
            permissions={[
              'Read your charging sessions',
              'Start and stop charging sessions',
            ]}
          />

          <CardActions>
            <CardAction type="primary">
              <Button
                className="!mt-12"
                variant="primary"
                id="connect-chargepoint-card-signin-button"
                type="submit"
              >
                Sign In
              </Button>
            </CardAction>
          </CardActions>
        </NavigationPage.SingleCardContent>
      </form>
      <ChargePointAuthenticateStatusPage
        id="chargepoint-authentication-status-page"
        onConfirmed={onStatusConfirmed}
        onClose={onRestart}
        onCancelOrRetry={onRestart}
        open={status !== null}
        status={status ?? 'connecting'}
        connectedChargers={createdVehicleChargers}
        error={creationError}
      />
    </NavigationPage>
  )
}
