import { Flex, FlexProps, Text, Icon } from '@/components'
import twMerge from '@/utils/classes/twMerge'

export type Props = FlexProps & {
  label: string
}

export default function ScrollIndicator(props: Props) {
  const { label, className: providedClasses, ...flexProps } = props
  const className = twMerge('z-2', providedClasses)
  return (
    <Flex
      container
      alignItems="center"
      direction="column"
      className={className}
      {...flexProps}
    >
      <>
        <Text variant="subheader" className="mb-1">
          {label}
        </Text>
        {[...Array(3).keys()].map((x) => (
          <Icon
            key={x}
            name="ChevronDown"
            size={16}
            color="#5D5D5D"
            className="mt-[-4px]"
          />
        ))}
      </>
    </Flex>
  )
}
