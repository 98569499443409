import { AdaptiveModal } from '@/app/components'
import ConnectDeviceConfirmation from '@/app/features/connect-device/ConnectDeviceConfirmation'
import { ModalProps } from '@/components'
import { VehicleCharger } from '@/types'

type Props = ModalProps & {
  onConfirmed: () => void
  status: 'connecting' | 'connected' | 'failed'
  onCancelOrRetry: () => void
  connectedChargers: VehicleCharger[]
  error?: string
}

function chargerToDeviceConnection() {
  return {
    device: {
      name: 'ChargePoint',
      description: 'HomeFlex',
    },
    permissions: [
      {
        name: 'Charge Control',
        allowed: true,
      },
      {
        name: 'Charge State',
        allowed: true,
      },
    ],
  }
}

function useViewModel(props: Props) {
  const { connectedChargers, ...rest } = props
  const deviceConnections = connectedChargers.map(chargerToDeviceConnection)
  return {
    ...rest,
    deviceConnections,
  }
}

const ChargePointAuthenticateStatusPage = (props: Props) => {
  const viewModel = useViewModel(props)
  const {
    onConfirmed,
    status,
    onCancelOrRetry,
    deviceConnections,
    error,
    ...modalProps
  } = viewModel
  return (
    <AdaptiveModal {...modalProps}>
      <ConnectDeviceConfirmation
        onConfirmed={onConfirmed}
        onCancelOrRetry={onCancelOrRetry}
        status={status}
        deviceConnections={deviceConnections}
        title="Great! We connected your charger"
        errorMessage={error}
      />
    </AdaptiveModal>
  )
}

export default ChargePointAuthenticateStatusPage
