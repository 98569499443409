import { NavigationPage } from '@/app/components'
import { useNavigation } from '@/app/hooks'
import {
  BoxedSymbol,
  Button,
  CardAction,
  CardActions,
  Flex,
  IconDetailListItem,
  List,
} from '@/components'
import { IconNames } from '@/components/Icon/Icon'
import { useRouteMatch } from 'react-router-dom'

const listContent = [
  {
    icon: 'Shield',
    title: 'Trusted & secure',
    description:
      'Your information is handled securely with end-to-end encryption.',
  },
  {
    icon: 'Key',
    title: 'Privacy first',
    description:
      'You can review a list of permissions before granting Optiwatt access.',
  },
]

export default function ConnectChargepointPage() {
  const { url } = useRouteMatch()
  const navigation = useNavigation()

  const onConnectClick = () => {
    navigation.push(`${url}/authenticate`)
  }

  return (
    <NavigationPage
      id="connect-chargepoint-entry-page"
      title="Connect Smart Charger"
      subtitle="Optiwatt uses your smart charger’s login to connect your device."
    >
      <NavigationPage.SingleCardContent>
        <List gap="md">
          {listContent.map((item, index) => (
            <Flex key={index} item>
              <IconDetailListItem
                icon={() => (
                  <BoxedSymbol
                    variant="icon"
                    iconName={item.icon as IconNames}
                  />
                )}
                title={item.title}
                description={item.description}
              />
            </Flex>
          ))}
        </List>
        <CardActions>
          <CardAction type="primary">
            <Button
              id="connect-smart-charger-info-connect-button"
              variant="primary"
              className="!mt-12"
              onClick={onConnectClick}
            >
              Connect
            </Button>
          </CardAction>
        </CardActions>
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
