import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import ConnectChargepointPage from './chargepoint'
import ChargePointAuthenticatePage from './chargepoint/authenticate'
import { BehindFlag } from '@/app/components'
import SelectChargerDevicePage from './select'
import ConnectChargerValuePropPage from './value-prop'
import BrandNotFoundPage from './not-found'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'

const formBasePath = ({
  path,
  params,
}: {
  path: string
  params: Record<string, string>
}) => {
  let newPath = path.replaceAll('*', '')
  for (const key in params) {
    if (params[key] !== undefined) {
      const regex = new RegExp(`:(${key})\\??`, 'g')
      newPath = newPath.replace(regex, params[key])
    }
  }
  return newPath
}

export default function ConnectChargerRoutes() {
  const match = useRouteMatch()
  const path = formBasePath(match)
  const pathSegments = path.split('/')
  const isAva = useIsAvaBasePath()
  if (isAva && !pathSegments.includes('onboarding')) {
    pathSegments.splice(2, 0, 'onboarding')
  }
  const basePath = pathSegments.join('/')

  return (
    <BehindFlag flag="connect_charger" experimental>
      <Switch>
        <Route exact path={`${basePath}/value-prop`}>
          <ConnectChargerValuePropPage />
        </Route>
        <Route exact path={`${basePath}/select`}>
          <SelectChargerDevicePage />
        </Route>
        <Route exact path={`${basePath}/not-found`}>
          <BrandNotFoundPage />
        </Route>
        <Route exact path={`${basePath}/chargepoint`}>
          <ConnectChargepointPage />
        </Route>
        <Route path={`${basePath}/chargepoint/authenticate`}>
          <ChargePointAuthenticatePage />
        </Route>
        <Redirect to={`${basePath}/value-prop`} />
      </Switch>
      {/* Add more routes as needed, using `${path}` to build the route */}
    </BehindFlag>
  )
}
