import { CheckedListItem, Flex, List, Text } from '@/components'

export type Props = {
  title: string
  permissions: string[]
}

export default function TitledCheckedList({ permissions, title }: Props) {
  return (
    <Flex container direction="column" spacing={2}>
      <Flex item>
        <Text>{title}</Text>
      </Flex>
      <Flex item>
        <List>
          {permissions.map((permission) => (
            <CheckedListItem key={permission}>{permission}</CheckedListItem>
          ))}
        </List>
      </Flex>
    </Flex>
  )
}
