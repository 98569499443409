import React from 'react'
import './styles.scss'
import { makeStyles } from '@material-ui/core/styles'
import { Typography as MuiTypography, styled, Paper } from '@material-ui/core'
import { spacing } from '@material-ui/system'
import { Button } from '@/components'
import { Check } from 'react-feather'
import DeviceConnectionRedirect, {
  ConnectionStatusMode,
} from '@/authenticated/components/DeviceConnectionRedirect'
import { DeviceConfirmationPanel } from '@/app/components'
import { ConnectDeviceConfirmationProps } from './ConnectDeviceConfirmation.types'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '../../../../v2/theme'
import useUserWhitelabel from '@/hooks/whitelabel/useUserWhitelabel'
import { remoteConfig } from '@/firebase'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    paddingTop: theme.spacing(6.5),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
      paddingTop: theme.spacing(4),
    },
  },
  connectButton: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 20,
  },
}))
const Typography = styled(MuiTypography)(spacing)

const ConnectDeviceConfirmation: React.FC<ConnectDeviceConfirmationProps> = ({
  status,
  onConfirmed,
  onCancelOrRetry,
  disableButton = false,
  deviceConnections,
  title,
  errorMessage,
}) => {
  const classes = useStyles()
  const isAva = useIsAvaBasePath()
  if (['connecting', 'failed'].includes(status)) {
    const mode = {
      connecting: ConnectionStatusMode.Connecting,
      failed: ConnectionStatusMode.Failed,
      connected: ConnectionStatusMode.Connected,
    }
    return (
      <DeviceConnectionRedirect
        mode={mode[status]}
        buttonAction={onCancelOrRetry}
        statusText={{
          connecting: 'Hold tight...we’re finishing up your connection',
          connected: 'Your connection is complete!',
          failed:
            errorMessage ??
            'Oh no! Something went wrong. Please go back and try again',
        }}
      />
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper className={classes.wrapper} elevation={0}>
        <div className="confirmation-container">
          <div>
            <div className="confirmation-header">
              <div className="title-container">
                <div className="icon-container">
                  <Check className="check" />
                </div>
                <Typography variant="h4">{title}</Typography>
              </div>
              <Typography variant="body1">
                The capabilities of each car are listed below:
              </Typography>
            </div>
            {deviceConnections.map((deviceConnection) => (
              <DeviceConfirmationPanel
                key={deviceConnection.device.name}
                device={deviceConnection.device}
                permissions={deviceConnection.permissions}
              />
            ))}
          </div>
          <Button
            id="confirm-device-connection-button"
            className={classes.connectButton}
            onClick={onConfirmed}
            variant="primary"
            disabled={disableButton}
          >
            {isAva ? 'Next' : 'Continue'}
          </Button>
        </div>
      </Paper>
    </ThemeProvider>
  )
}

export default ConnectDeviceConfirmation
