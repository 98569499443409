import { NavigationPage } from '@/app/components'
import { useNavigation } from '@/app/hooks'
import SurveyObserver from '@/app/components/SurveyObserver'
import { Card, NavigationList } from '@/components'
import useUserWhitelabel from '@/hooks/whitelabel/useUserWhitelabel'
import { remoteConfig } from '@/firebase'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'
import { useLocation } from 'react-router-dom'

const devices = [
  {
    id: 'connect-chargepoint',
    name: 'Chargepoint',
    logo: '/img/chargepoint.webp',
    destination: '/connect-device/charger/chargepoint',
  },
]

function useNavigateOnClick(destination: string) {
  const navigation = useNavigation()
  return () => navigation.push(destination)
}

export default function SelectChargerDevicePage() {
  const isAva = useIsAvaBasePath()
  const isOnboarding = useLocation().pathname.includes('/onboarding/')

  return (
    <NavigationPage id="connect-device-page" title="Connect Device">
      <Card style={{ paddingTop: 0, paddingBottom: 0 }}>
        <NavigationList>
          {devices.map((device, index) => (
            <NavigationList.BasicItem
              key={`${device.name}-${index}`}
              id={`${device.id}-nav-item`}
              label={device.name}
              variant="image"
              imgSrc={device.logo}
              onClick={useNavigateOnClick(
                `${isAva && isOnboarding ? '/ava/onboarding' : ''}${
                  device.destination
                }`,
              )}
              labelClasses="!text-base pl-4 self-start flex self-center"
            />
          ))}
          <SurveyObserver observedSurveys={['MissingBrand']}>
            <NavigationList.BasicItem
              key="i-dont-see-my-brand"
              id="i-dont-see-my-brand-nav-item"
              variant="character"
              character="?"
              label="I don't see my brand"
              onClick={useNavigateOnClick(
                `${
                  isAva && isOnboarding ? '/ava/onboarding' : ''
                }/connect-device/charger/not-found`,
              )}
              labelClasses="!text-base pl-4 self-start flex self-center"
              characterClasses="self-center flex self-start m-auto"
            />
          </SurveyObserver>
        </NavigationList>
      </Card>
    </NavigationPage>
  )
}
