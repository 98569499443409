import { NavigationPage, ScrollIndicator } from '@/app/components'
import { useNavigation } from '@/app/hooks'
import { useIsAvaBasePath } from '@/app/hooks/ava/useAvaVerification'
import ListableInformationCard from '@/authenticated/components/ConnectDeviceInformationCard/ListableInformationCard'
import { Button, CardAction, CardActions } from '@/components'
import { BreakpointContext } from '@/context'
import theme from '@/v2/theme'
import { ThemeProvider } from '@material-ui/core/styles'
import { useContext } from 'react'
import { useLocation } from 'react-router-dom'

const listContent = [
  {
    title: 'Charge and save',
    description:
      'Automatically schedule your home charging to minimize your utility bill, grid emissions, or both',
  },
  {
    title: 'Smart Charging',
    description:
      'Arrive at an optimal balance of comfort and cost by eliminating wasted energy in your daily routine',
  },
  {
    title: 'Stay informed',
    description:
      'Visualize your costs over any time period, and see how seasonal changes affect your utility bill',
  },
]

export default function ConnectChargerValuePropPage() {
  const breakpoint = useContext(BreakpointContext)
  const navigation = useNavigation()
  const isAva = useIsAvaBasePath()
  const isOnboarding = useLocation().pathname.includes('/onboarding/')
  const onGetStartedClick = () => {
    if (isAva && isOnboarding) {
      navigation.push(`/ava/onboarding/connect-device/charger/select`)
      return
    }
    navigation.push('/connect-device/charger/select')
  }

  return (
    <NavigationPage
      id="connect-charger-value-prop-page"
      title="Connect Home Charger"
      subtitle="Smart charging - Optiwatt helps you charge your car during the cheapest hours, automatically."
    >
      <NavigationPage.SingleCardContent>
        <div className="grid gap-4 mb-4">
          <img
            src="/img/chargepoint-charger-lg.webp"
            alt="home smart charger"
            className="mb-6 mx-auto w-[40%] h-auto aspect-[0.63/1]"
          />
          {breakpoint.xsOnly && (
            <ScrollIndicator label="Scroll for more" className="mb-6" />
          )}
        </div>
        <ThemeProvider theme={theme}>
          {listContent.map((item, index) => (
            <ListableInformationCard
              key={index}
              title={item.title}
              subtitle={item.description}
              stringIcon={String(index + 1)}
            />
          ))}
        </ThemeProvider>
        <CardActions className="sticky bottom-5">
          <CardAction type="primary">
            <Button
              id="connect-smart-charger-info-connect-button"
              variant="primary"
              onClick={onGetStartedClick}
            >
              Get started
            </Button>
          </CardAction>
        </CardActions>
      </NavigationPage.SingleCardContent>
    </NavigationPage>
  )
}
